import { getPhoneNumberInfos } from '@ds/components/InputPhone'

import type { Address } from '../types/address'

export function useFormatPhone(address: Address): string {
  const { country, nationalNumber, internationalNumber } = getPhoneNumberInfos(
    `${address.countryDialInCode}${
      address.countryDialInCode ? ' ' : ''
    }${address.phone}`,
  )

  return country === address.country ? nationalNumber : internationalNumber
}
